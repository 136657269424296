<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <ayl-new-table
          v-loading="loading"
          :data="tableData"
          pagination
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100]"
          :pager-count="5"
          :current-page.sync="current"
          :total="totalNum"
          :page-size="10"
          @size-change="handleSizeChange"
          @ayl-current-change="handleCurrentChange"
          @getSearchPramas="getSearchPramas"
        >
          <template #after>
            <el-button style="margin-right: 18px" type="primary" @click="onSearch"
              >查询</el-button
            >
            <el-link type="primary" @click="dialogExport = true">查询数据导出</el-link>
          </template>
        </ayl-new-table>
        <!--导出-->
        <el-dialog
          title="选择导出的数据"
          :visible.sync="dialogExport"
          width="540px"
          center
        >
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <el-checkbox-group v-model="checkIndex" @change="handleCheckedCitiesChange">
            <el-checkbox
              :label="item.index"
              v-for="(item, idx) in checkList"
              :key="idx"
              >{{ item.value }}</el-checkbox
            >
          </el-checkbox-group>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="exportExcel" :loading="btnLoading"
              >确 定</el-button
            >
            <el-button @click="dialogExport = false">取 消</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "../../../utils/mixins";
import newTableMixins from "../../../utils/new-table-mixins";
export default {
  mixins: [mixins.$exportCheckAll, newTableMixins],
  data() {
    return {
      nav: [
        { name: "业务报表", path: "" },
        { name: "客户报表", path: "" },
      ],
      dialogExport: false,
      checkList: [],
      checkIndex: [],
      btnLoading: false,

      tableData: {
        api: this.$api.customerPartner,
        query: {
          startTime: null,
          endTime: null,
          orderType: null,
          queryContent: null,
        },
        searchData: [
          {
            title: "日期",
            type: "dateRange",
            model: ["startTime", "endTime"],
          },
          {
            title: "业务类型",
            type: "select",
            model: "orderType",
            placeholder: "请选择",
            option: this.$enums.OrderNewType.list,
          },
          {
            type: "input",
            model: "queryContent",
            placeholder: "客户名称",
          },
        ],
        columns: [
          {
            title: "客户名称",
            key: "partnerName",
            width: "190px",
            fixed: "left",
            showTooltip: true,
          },
          {
            title: "车次",
            width: "100px",
            fixed: "left",
            key: "transportNum",
          },
          {
            title: "重量(吨)",
            width: "90px",
            fixed: "left",
            key: "weight",
          },
          {
            title: "里程",
            width: "100px",
            fixed: "left",
            key: "mileage",
          },
          {
            title: "收入",
            width: "120px",
            key: "income",
            fixed: "left",
            showTooltip: true,
          },
          {
            title: "成本",
            width: "100px",
            key: "cost",
            showTooltip: true,
          },
          {
            title: "信息费",
            width: "100px",
            key: "messageFee",
            showTooltip: true,
          },
          {
            title: "代垫费用",
            key: "advanceFee",
            showTooltip: true,
          },
          {
            title: "毛利",
            key: "grossProfit",
            showTooltip: true,
          },
          {
            title: "平均每车次收入",
            key: "averageIncome",
            showTooltip: true,
          },
          {
            title: "平均每车次毛利",
            key: "averageGrossProfit",
            showTooltip: true,
          },
          {
            title: "操作",
            width: "78px",
            align: "center",
            render: (h, ctx) => {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view": true,
                    },
                    on: {
                      click: () => {
                        this.goEdit(ctx.row);
                      },
                    },
                  },
                  "订单明细"
                ),
              ]);
            },
          },
        ],
        data: [],
      },
    };
  },
  methods: {
    // 导出
    async exportExcel() {
      this.btnLoading = true;
      try {
        const url = await this.$api.reportExcel({
          params: {
            startTime: this.tableData.query.startTime,
            endTime: this.tableData.query.endTime,
            orderType: this.tableData.query.orderType,
            queryContent: this.tableData.query.queryContent,
          },
          excelKey: "CustomerOrderReport",
          indexList: this.checkIndex,
        });
        window.open(url);
        this.dialogExport = false;
      } catch (e) {}
      this.btnLoading = false;
    },

    // 详情
    goEdit(val) {
      this.$router.push({
        path: "/business-report/order-details",
        query: {
          startTime: this.tableData.query.startTime,
          endTime: this.tableData.query.endTime,
          orderType: this.tableData.query.orderType,
          queryContent: val.partnerId,
          type: "customer",
          queryType: 0,
        },
      });
    },
  },
  async mounted() {
    // 获取导出列表
    this.$api
      .GetExcelExportKeyAndColumn({
        excelKey: "CustomerOrderReport",
      })
      .then((res) => {
        this.checkList = res.chsList;
        this.checkIndex = this.checkList.map((e) => {
          return e.index;
        });
      });
  },
};
</script>

<style lang="sass" scoped></style>
